
import styled from "styled-components";

interface AdUnitProps {
  id?: string; // Allow id prop of type string (optional)
}

const AdUnit = styled.div<AdUnitProps>`
  display: block !important;
  background-image: url(https://cdn.jsdelivr.net/gh/corover/assets@23Dec-t/askdisha-bucket/300_250-1.png);
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center;
  min-width: 300px;
  min-height: 250px;
`;

export default AdUnit;
