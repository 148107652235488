import { useRef, useState } from "react";
import { IMAGE_LANG, IMAGE_MIC, IMAGE_SEND } from "../../../../../Assets";
import { isShowMic } from "../../../../../Helpers/misc";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";

import { Microphone } from "../../../../Microphone";
import AutoSuggestions from "../AutoSuggestions";
import LanguageMenu from "../LanguageMenu";
import { getPlaceholder } from "../utils";
import Container from "./styles";
const TextAndMic = ({ handleSendQueryPayload }) => {
  const ref = useRef<HTMLInputElement>(null);
  const currentFlowStep = useGetReduxState().behaviour.current;
  const [micOn, setMicon] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const lang = useGetReduxState().behaviour.lang;
  const faqs = useGetReduxState().faqs[lang];
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && ref.current.value !== "") {
      handleSendMessage();
    }
  };

  // const sendError = (msg) => {
  //   addUserMessage({
  //     text: "",
  //   });
  //   addResponseMessage({
  //     text: msg,
  //     id: Date.now().toString(),
  //     showAvatar: true,
  //   });
  // };
  const translations = {
    en: {
      book: "I want to book a Ticket",
    },
    hi: {
      book: "मुझे टिकट बुक करनी है",
    },

    gu: {
      book: "મારે ટિકિટ બુક કરાવવાની છે",
    },
  };
  const handleSendMessage = async () => {
    let value = ref.current.value.trim();
    if (value !== "") {
      setShowSuggestions(false);

      handleSendQueryPayload({ query: value, inputType: "TEXT" });

      ref.current.value = "";
    }
  };

  const handleVoice = (value) => {
    if (value) {
      setMicon(false);
      handleSendQueryPayload({ query: value, inputType: "VOICE" });
    }
  };

  const isForPNR = (current) => {
    if (current === "ENTER_PNR") return true;
    return false;
  };

  const filterQuestions = (e) => {
    let input = e.target.value;
    if (input === "" || isForPNR(currentFlowStep)) {
      setShowSuggestions(false);
      return;
    }
    let filtered = faqs.filter((element) =>
      element.toLowerCase().includes(input.toLowerCase())
    );

    if (filtered.length > 0) {
      setSuggestions(filtered.slice(0, 6));
      setShowSuggestions(true);
    } else setShowSuggestions(false);
  };

  return (
    <Container upperBoundary={!showSuggestions}>
      <div
        tabIndex={0}
        aria-label="Language Menu Button"
        className="lang-menu-btn"
        onClick={() => setShowMenu(true)}
        onKeyDown={(e) => {
          if (e.key === "Enter") setShowMenu(true);
        }}
      >
        <img src={IMAGE_LANG} alt="" />
      </div>

      {currentFlowStep == "ENTER_PNR" ? (
        <input
          ref={ref}
          type={"number"}
          placeholder={getPlaceholder(currentFlowStep)}
          onKeyDown={handleKeyDown}
          onChange={filterQuestions}
          tabIndex={0}
          aria-label="Query textbox"
          autoFocus
        />
      ) : (
        <input
          ref={ref}
          type={"text"}
          placeholder={getPlaceholder(currentFlowStep)}
          onKeyDown={handleKeyDown}
          onChange={filterQuestions}
          tabIndex={0}
          aria-label="Query textbox"
          autoFocus
        />
      )}

      {/* <input
        ref={ref}
        type={currentFlowStep == "ENTER_PNRY" ? "number" : "text"}
        placeholder={getPlaceholder(currentFlowStep)}
        onKeyDown={handleKeyDown}
        onChange={filterQuestions}
        tabIndex={0}
        aria-label="Query textbox"
      /> */}

      {isShowMic() && (
        <img
          className="mic-btn"
          id="mic"
          src={IMAGE_MIC}
          alt="Microphone Button"
          onClick={() => setMicon(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") setMicon(true);
          }}
        />
      )}

      <img
        className="send-btn"
        src={IMAGE_SEND}
        alt="Send Message Button"
        onClick={handleSendMessage}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSendMessage();
        }}
      />

      {micOn && (
        <Microphone
          callback={handleVoice}
          onClose={() => {
            setMicon(false);
          }}
          useEnglish={false}
          content={translations[lang].book}
        />
      )}
      {showMenu && <LanguageMenu handleClose={() => setShowMenu(false)} />}
      {showSuggestions && (
        <AutoSuggestions
          handleClose={() => {
            ref.current.value = "";
            setShowSuggestions(false);
          }}
          suggestions={suggestions}
        />
      )}
    </Container>
  );
};

export default TextAndMic;
