import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import BookingForm from "../BookingForm";
// import BookingsContainer from "./Bookings/Bookings";
import howWorkstext from "../About/translations";
import About from "../About/About";
import HowWorks from "../HowWorks/HowWorks";
import Menu from "../Menu";
import Advertise from "../Advertise/Advertise";
import Greeting from "../Greeting";
import translations from "../translations";
import AdComponent from "./styles";
import UpcomingBookings from "../UpcomingBookings";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import { setJourney } from "../../../../../../../Store/Dispatcher/journey";
import useSendQuery from "../../../../../../../Hooks/useSendQuery/useSendQuery";
import Speaker from "../../../../../../../UI/UIComponents/Speaker";
const bookings =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/ticket-3d.svg";
const looking =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/looking.svg";

const AD = () => {
  const [howWorksExpanded, setHowWorksExpanded] = useState(false);
  const [AboutExpanded, setAboutExpanded] = useState(true);
  // const [Bookings, setBookings] = useState([]);
  const [bookingsExpanded, setBookingsExpanded] = useState(false);
  // const journey = useGetReduxState().journey;
  // const [loading, setLoading] = useState(false);
  // const api = new Api();
  const lang = useGetReduxState().behaviour.lang;
  let langText = translations[lang];
  const updateBookings = () => {
    // setLoading(true);
    // api.getBookings().then((data) => {
    //   setBookings(data.renderTemplate.data.upcomingjourney);
    //   setLoading(false);
    // });
    // setBookings([]);
    // setLoading(false);
  };

  useEffect(() => {
    if (bookingsExpanded) {
      updateBookings();
    }
  }, [bookingsExpanded]);
  const { sendQueryPayload } = useSendQuery(() => {});

  const findTrains = ({ source, destination, date, quota, isDirect }) => {
    setJourney({ source, destination, quota, date, boardingStation: "" });
    if (source && destination && date && quota) {
      sendQueryPayload({
        inputType: "",
        query: "SHOW_TRAINS",
        data: "SHOW_TRAINS",
        isDirect,
      });
    }
    // showTrains();
  };

  // const flavoursContainer = document.getElementById('flavoursContainer');
  // const flavoursScrollWidth = flavoursContainer.scrollWidth;

  // window.addEventListener('load', () => {
  //   window.self.setInterval(() => {
  //     if (flavoursContainer.scrollLeft !== flavoursScrollWidth) {
  //       flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 1, 0);
  //     }
  //   }, 15);
  // });

  return (
    <AdComponent />
  );
};

export default AD;
